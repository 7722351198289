<template>
  <DataTable
    widget-classes="mb-5 mb-xl-8"
    :refs="refs"
    :colspan="7"
    :hasSearchFilter="true"
    :hasDateFilter="true"
    :hasStatusFilter="true"
    :hasHashId="true"
    :hasBulkAction="true"
    :hasExport="true"
    :addNew="addNew"
    :hasShowEntries="true"
    :lblSearch="$t('app.search_by_name_or_email')"
    :image="$t('view.image')"
    :image_style="'width: 40px;height: 40px;border-radius: 50%'"
    :hasName="true"
    :name="$t('view.company_name')"
    :hasEmail="true"
    :email="$t('view.email')"
    :hasMobile="true"
    :mobile="$t('view.mobile')"
    :hasDate="true"
    :hasStatus="true"
    :hasTotalPaidInvoicesCount="true"
    :hasInvoices="true"
    :hasActions="true"
    :hasDeleteButton="true"
    :actionable="true"
    :sorDataTable="true"
    v-on:hasAddNewPermission="handlePermissoinChange"
  >
  </DataTable>
</template>

<script>
import { defineComponent } from "vue";
import DataTable from "@/components/DataTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "List",
  components: {
    DataTable,
  },
  data() {
    return {
      //
      addNew: false,
      refs: "companies",
    };
  },
  created() {
    setCurrentPageTitle(this.$t("nav.companies"), "bx bxs-user-rectangle");
  },
  methods: {
    handlePermissoinChange(event) {
      this.addNew = event;
    },
  },
});
</script>
